<template>
  <div
    style="
      position: relative;
      width: 100vw;
      height: 100vh;
      background-color: #000;
    "
  >
    <q-icon
      style="
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: #fff;
        border-radius: 50%;
        border: none;
        z-index: 999;
      "
      size="25px"
      color="grey-6"
      name="close"
      @click="goBack"
    />

    <p
      style="
        margin: 0;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: red;
        font-size: 1.1rem;
        letter-spacing: 3px;
        text-align: center;
      "
      v-if="errMsg"
    >
      {{ errMsg }}
    </p>

    <div
      class="qr-scanner"
      style="
        position: absolute;
        width: 90vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 60vh;
      "
    >
      <qr-stream
        :camera="camera"
        @init="onInit"
        @decode="onDecode"
        v-show="!errMsg"
      >
        <div class="box">
          <div class="line"></div>
          <div class="angle"></div>
        </div>
      </qr-stream>
    </div>
  </div>
</template>

<script>
import { QrStream } from "vue3-qr-reader";
export default {
  components: {
    QrStream,
  },
  data() {
    return {
      camera: "off",
      errMsg: "",
    };
  },
  mounted() {
    this.open();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    open() {
      this.camera = "rear";
      window.sessionStorage["scan"] = "";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error) this.errMsg = this.$t("common.item_38");
      }
    },
    onDecode(data) {
      this.camera = "off";
      window.sessionStorage["scan"] = data;
      this.$router.push({ path: "/quick" });
    },
  },
};
</script>

<style scoped>
.qr-scanner {
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent
    );
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #111;
}

.qr-scanner .box {
  width: 75vw;
  height: 75vw;
  max-height: 75vh;
  max-width: 75vh;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid rgba(0, 255, 51, 0.2);
}

.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%);
  border-bottom: 3px solid #00ff33;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;

  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: #00ff33;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #00ff33;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #00ff33;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #00ff33;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
